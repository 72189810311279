import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { GetAreaListReq } from './types'
import { getArealListReq } from './actions'

export const getAreaList = (data: GetAreaListReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetAreaListReq>({
    method: 'POST',
    path: 'GetAreaData',
    data,
    token: `Bearer ${token}`,
  })(getArealListReq)
}
