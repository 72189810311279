import React, { useState } from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import {
  CmAcceptFooter,
  RejectModal,
  CmInproFooter,
  MainConChecklist,
  CmAcceptMcTaskFooter,
} from 'Components/Activity'

import {
  Activity,
  CurrentActivity,
  VendorCodeItem,
} from 'features/cmpj/activity/types'
import { MainconCheckList, InspectionList } from 'features/types/checklist'
import { StatusOfTask } from 'features/types/status'
import TaskHeader from './TaskHeader'
import MainConTaskInfo from './MainConTaskInfo'
import TaskProgress from './TaskProgress'
import Breadcrumbs from './Breadcrumbs'
import { verifyEmptyRemark } from 'utils/verify'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  onHistoryOpen?: () => void
  originalData: Activity
  currentData: CurrentActivity
  checkListData: MainconCheckList
  onRejectTask: (remark: string) => void
  onAcceptTask: () => void
  onSendTask: () => void
  onTakeActivity: () => void
  onUpdateCheckList: (val: MainconCheckList) => void
  onUpdateActivity: (val: CurrentActivity) => void
  wbs?: any
  isLoading?: boolean
  vendorList: VendorCodeItem[]
}

const { Content, Footer } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  currentData,
  checkListData,
  onRejectTask,
  onAcceptTask,
  onSendTask,
  onTakeActivity,
  onUpdateCheckList,
  onUpdateActivity,
  isLoading = false,
  vendorList,
  wbs,
  onHistoryOpen,
}) => {
  const [showRejectModal, setShowRejectModal] = useState(false)
  const { State, TaskStatus } = originalData

  // Mock data
  const isCM = State === 'CM'
  const isPJ = State === 'PJ'
  const isMainconTask = State === 'MC' && TaskStatus === 'INIT'

  const onChangeChecklist = (val: MainconCheckList) =>
    onUpdateCheckList({ ...val })

  const onSubmitReject = (val: string) => {
    onRejectTask(val)
    setShowRejectModal(false)
  }

  const onActivity = (val: CurrentActivity) => onUpdateActivity({ ...val })

  const isDisableSubmitBotton = () => {
    const isEmptyRemark = verifyEmptyRemark(checkListData.InspectionList)
    const isEmptyFinishDate =
      currentData.ExpectFinishDate === '' && currentData.ActualFinishDate === ''
    const isEmptyActualStartDate = currentData.ActualStartDate === ''
    const isInvalideProgress =
      currentData.RequestProgress === 0 ||
      currentData.CurrentProgress === currentData.RequestProgress
    const disabledAccept =
      isEmptyFinishDate ||
      isInvalideProgress ||
      isEmptyRemark ||
      isEmptyActualStartDate
    return disabledAccept
  }

  let footer
  if (isCM) {
    switch (TaskStatus) {
      case StatusOfTask.INIT:
        footer = (
          <Footer className="detail-page-footer">
            <CmAcceptFooter
              onClickReject={() => setShowRejectModal(true)}
              onClickAccept={onAcceptTask}
              isLoading={isLoading}
            />
          </Footer>
        )
        break
      case StatusOfTask.INPRO:
      case StatusOfTask.REJT:
        const disabledAccept = isDisableSubmitBotton()
        footer = (
          <Footer className="detail-page-footer">
            <CmInproFooter
              onClickReject={() => setShowRejectModal(true)}
              onClickAccept={disabledAccept ? undefined : onSendTask}
              isLoading={isLoading}
            />
          </Footer>
        )
        break
      default:
        break
    }
  }

  if (isMainconTask) {
    footer = (
      <Footer className="detail-page-footer">
        <CmAcceptMcTaskFooter
          onClickAccept={onTakeActivity}
          isLoading={isLoading}
        />
      </Footer>
    )
  }

  const rejectModal = (
    <RejectModal
      visible={showRejectModal}
      onClose={() => setShowRejectModal(false)}
      onSubmit={(val: string) => onSubmitReject(val)}
    />
  )

  let renderTaskProgress
  switch (State) {
    case 'CM':
      if (
        TaskStatus === StatusOfTask.INPRO ||
        TaskStatus === StatusOfTask.REJT
      ) {
        renderTaskProgress = (
          <TaskProgress
            data={currentData}
            original={originalData}
            onChange={onActivity}
            onDocumentOpen={onDocumentOpen}
            onImageOpen={onImageOpen}
            onThumbnailOpen={onThumbnailOpen}
            vendorList={vendorList}
          />
        )
      }
      break
    case 'PJPO':
      renderTaskProgress = (
        <TaskProgress
          data={currentData}
          original={originalData}
          onChange={onActivity}
          onDocumentOpen={onDocumentOpen}
          onImageOpen={onImageOpen}
          onThumbnailOpen={onThumbnailOpen}
          readOnly={true}
          vendorList={vendorList}
        />
      )
      break
    default:
      break
  }

  const renderChecklist = () => {
    let hideStatus = false
    if (isCM) {
      if (TaskStatus === StatusOfTask.INIT) {
        hideStatus = true
      } else if (
        TaskStatus === StatusOfTask.INPRO ||
        TaskStatus === StatusOfTask.REJT
      ) {
        return (
          <MainConChecklist
            checkListData={checkListData}
            onUpdateCheckList={onChangeChecklist}
            showWarningList={true}
          />
        )
      }
    }

    return (
      <MainConChecklist checkListData={checkListData} hideStatus={hideStatus} />
    )
  }

  return (
    <>
      <Breadcrumbs activity={originalData} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <ActivityDetailArea>
            <TaskHeader
              originalData={originalData}
              onHistoryOpen={onHistoryOpen}
              wbs={wbs}
            />
            {!isMainconTask && (
              <MainConTaskInfo
                original={originalData}
                onDocumentOpen={onDocumentOpen}
                onImageOpen={onImageOpen}
                onThumbnailOpen={onThumbnailOpen}
              />
            )}
            {renderTaskProgress}
            {renderChecklist()}
          </ActivityDetailArea>
        </div>
      </Content>
      {footer}
      {rejectModal}
    </>
  )
}

const ActivityDetailArea = styled.div`
  .box-section {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    padding: 24px;
    line-height: normal;
    &.no-space {
      padding: 0;
      margin-top: 0px;
    }
    .title {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
    }
    .description {
      font-size: 20px;
    }
  }
  .space {
    margin-top: 24px;
  }
`

export default ActivityDetail
