import { Moment } from 'moment'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { TaskStatus, Task } from '../../tasklane/tasklane.types'
import { MainconCheckList, InspectionList } from 'features/types/checklist'
import { Event } from '../../types/event'

export type ActivityAction = ActionType<typeof actions>

export type TaskById = { [key in string]: Task }

export interface ActivityState {
  CurrentActivity: CurrentActivity
  OriginalActivity: Activity
  CheckList: MainconCheckList
  UploadList: any[]
  Event: Event
  EventCheckList: Event
  VendorList: VendorCodeItem[]
}
export interface FetchVendorRequest {
  UserEmail: string
}

export interface VendorCodeItem {
  VendorName: string
  VendorCode: string
}

export type CurrentActivity = {
  Images: any[]
  Documents: any[]
  CurrentProgress: number
  RequestProgress: number
  ExpectFinishDate: string
  Remark: string
  ActualStartDate: string
  ActualFinishDate: string
  FlagERC: boolean
  FlagPost: boolean
  VendorCode: string
  VendorName: string
  Unit: string
}

export type Activity = {
  RowID: number
  ActivityID: string
  ActivityName: string
  BaselineStartDate: string
  BaselineFinishDate: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
  ExpectFinishDate: string
  AppointmentDate: string
  ConstructionTypeName: string
  WbsID: number[]
  Unit: string
  Zone: string
  TowerName: string
  FloorID: number
  FloorName: string
  QCTypeID: number
  QCTypeName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  State: string
  TaskStatus: string
  Images: []
  Documents: []
  CmImages: []
  CmDocuments: []
  McImages: []
  McDocuments: []
  McRemark: string
  CmRemark: string
  RejectReason: string
  SowName: string
  SowID: string
  RequestID?: string
  Vendor: { VendorCode: string; VendorName: string }
  FlagERC: boolean
  FlagPost: boolean
}

export interface FetchActivityRequest {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
}

export enum CheckListStatus {
  PASS = 'PASS',
  SKIP = 'SKIP',
  FAIL = 'FAIL',
  NOTCHECK = 'NOTCHECK',
}

export enum CheckListStatusText {
  PASS = 'ผ่าน',
  SKIP = 'ไม่มีการตรวจ',
  FAIL = 'ไม่ผ่าน',
  NOTCHECK = 'ยังไม่ได้ตรวจ',
}

export interface RejectActivityReq {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
  Remark: string
}

export interface AcceptActivityReq {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
}

export interface CmTakeActivityReq {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
}

export interface TakeActivityListByCMReq {
  UserEmail: string
  ProjectCode: string
  ActivityList: string[]
  UnitList: string[]
  TakeAll: boolean
  Module: string
}

export interface SendActivityReq {
  UserEmail: string
  ProjectCode: string
  ActivityID: string

  Images: any[]
  Documents: any[]

  RequestProgress: number
  ActualStartDate: string
  ActualFinishDate: string
  ExpectFinishDate: string
  Remark: string

  CheckListID: string
  InspectionList: InspectionList[]
  VendorCode: string
  UnitCode: string
}

export interface UpdateAttachmentReq {
  UserEmail: string
  RequestID: string
  Files: any[]
  RemoveFiles: any[]
}

export interface GetActivityApi extends GetApiResponse<Activity> {}
export interface GetCheckListApi extends GetApiResponse<MainconCheckList> {}
export interface UpdateActivityApi extends GetApiResponse<{}> {}
export interface GetVendorListApi extends GetApiResponse<VendorCodeItem[]> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}
