import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { APIPath } from '../../../services/APIPath'
import {
  FetchActivityRequest,
  RejectActivityReq,
  AcceptActivityReq,
  SendActivityReq,
  CmTakeActivityReq,
  TakeActivityListByCMReq,
  UpdateAttachmentReq,
  FetchVendorRequest,
} from './types'
import {
  getActivityDetailRequest,
  getCheckListRequest,
  rejectActivity,
  acceptActivity,
  sendReqToPJAsyncReq,
  cmTakeActivity,
  cmTakeActivityList,
  sendAttachmentList,
  getVendorListRequest,
} from './actions'

export const getActivityById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetQCActivityById',
    data,
    token: `Bearer ${token}`,
  })(getActivityDetailRequest)
}

export const getQCReportById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetQCReportById',
    data,
    token: `Bearer ${token}`,
  })(getActivityDetailRequest)
}

export const getCheckListById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetMainconCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListRequest)
}

export const rejectActivityRequestForCM = (
  data: RejectActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<RejectActivityReq>({
    method: 'POST',
    path: 'RejectActivityRequestForCM',
    data,
    token: `Bearer ${token}`,
  })(rejectActivity)
}

export const acceptActivityRequestForCM = (
  data: AcceptActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<AcceptActivityReq>({
    method: 'POST',
    path: 'AcceptActivityRequestByCM',
    data,
    token: `Bearer ${token}`,
  })(acceptActivity)
}

export const sendActivityToPJ = (data: SendActivityReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendActivityReq>({
    method: 'POST',
    path: 'SendActivityRequestForPJ',
    data,
    token: `Bearer ${token}`,
  })(sendReqToPJAsyncReq)
}

export const takeActivityByCM = (data: CmTakeActivityReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CmTakeActivityReq>({
    method: 'POST',
    path: 'TakeActivityByCM',
    data,
    token: `Bearer ${token}`,
  })(cmTakeActivity)
}

export const takeActivityListByCM = (
  data: TakeActivityListByCMReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<TakeActivityListByCMReq>({
    method: 'POST',
    path: 'TakeActivityListByCM',
    data,
    token: `Bearer ${token}`,
  })(cmTakeActivityList)
}

export const updateAttachmentActivity = (
  data: UpdateAttachmentReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateAttachmentReq>({
    method: 'POST',
    path: 'UpdateAttachmentToRequestActivity',
    data,
    token: `Bearer ${token}`,
  })(sendAttachmentList)
}

export const getERCVendorList = (data: FetchVendorRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchVendorRequest>({
    method: 'POST',
    path: 'GetERCVendorList',
    data,
    token: `Bearer ${token}`,
  })(getVendorListRequest)
}
