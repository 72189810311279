import { AreaState, AreaAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
}

const initialState: AreaState = {
  AreaList: {
    Total: 0,
    List: [],
  },
  Event: cloneDeep(initEvent),
}

export default function(
  state: AreaState = initialState,
  action: AreaAction
): AreaState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getArealListReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.AreaList = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getArealListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.getArealListReq.success):
        const ResponseData: any = action.payload.ResponseData || {}
        draft.AreaList = {
          Total: ResponseData.Total || 0,
          List: ResponseData || [],
        }
        draft.Event.IsLoading = false
        break
      default:
        return state
    }
  })
}
