import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton } from 'Components/Shared/Button'
import HeaderConfig from './HeaderConfig'
import ImageSetting from './ImageSetting'
import MappingUnit from './MappingUnit'
import AreaFloor from './AreaFloor'
import { GetModelListReq, ModelListItem } from 'features/admin/model/types'
import { MasterDataReqByProject } from 'features/masterData/types'
import { updateData, submitModelReq } from 'features/admin/model/actions'
import { selectModelMappingList } from 'features/admin/model/selector'
import { GetAreaListReq } from 'features/admin/area/types'
import { getArealListReq } from 'features/admin/area/actions'
import { getAreaList } from 'features/admin/area/api'
import { selectAreaList, selectEvent } from 'features/admin/area/selector'

type Props = ReduxProps &
  DispatchProps & {
    item: ModelListItem
    Token: string
    UserEmail: string
    ProjectCode: string
    onClose: () => void
    onSaveDetail: () => void
    pagination: any
  }

const ModelDetail: React.ComponentType<Props> = props => {
  const {
    item,
    areaList,
    modelMappingList,
    Token,
    UserEmail,
    ProjectCode,
    onClose,
    pagination,
    onSaveDetail,
  } = props

  const [direction, setDirection] = useState('L')
  const [errorValidate, setErrorValidate] = useState('')
  const [floor, setFloor] = useState(1)

  const dispatch = useDispatch()

  const onChangeData = (value: any) => {
    dispatch(updateData({ ...value }))
  }

  useEffect(() => {
    if (errorValidate !== '') {
      setErrorValidate('')
    }
  }, [item])

  useEffect(() => {
    onGetAreaListData()
  }, [])

  const onGetAreaListData = () => {
    const areaListReq: GetAreaListReq = {
      UserEmail,
    }
    dispatch(getAreaList(areaListReq, Token))
  }

  const onSave = () => {
    if (item.ModelName === '') {
      setErrorValidate('กรุณากรอกชื่อ model ก่อนบันทึก')
      return
    }
    if (item.ConfigList.length === 0) {
      setErrorValidate('กรุณาเลือก ชั้น ก่อนบันทึก')
      return
    }

    for (let i = 0; i < item.ConfigList.length; i++) {
      if (!item.ConfigList[i].ImageFloorPlan.FileName) {
        setErrorValidate(
          `กรุณาเลือก อัพโหลดรูป ${item.ConfigList[i].DirectionName} ก่อนบันทึก`
        )
        return
      }
    }
    const modelListReq: GetModelListReq = {
      ProjectCode,
      UserEmail,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }

    const mappingModelReq: MasterDataReqByProject = {
      UserEmail,
      ProjectCode,
    }

    console.log(item)

    dispatch(
      submitModelReq.request({
        model: item,
        Token,
        UserEmail,
        ProjectCode,
        modelListReq,
        mappingModelReq,
      })
    )
    setErrorValidate('')
    onSaveDetail()
  }

  return (
    <>
      <MainPage>
        <Header>
          <CloseIcon color="#8b99a7" size={24} onClick={onClose} />
        </Header>
        <HeaderConfig
          direction={direction}
          item={item}
          onChangeData={onChangeData}
          onDirection={(val: string) => setDirection(val)}
        />
        <ImageSetting
          direction={direction}
          floor={floor}
          item={item}
          onChangeData={onChangeData}
          onChangeFloor={(val: number) => setFloor(val)}
        />

        <Row type="flex" justify="space-between">
          <Col span={12}>
            <MappingUnit
              direction={direction}
              item={item}
              modelMappingList={modelMappingList}
            />
          </Col>

          <Col span={12}>
            <AreaFloor
              direction={direction}
              item={item}
              floor={floor}
              areaList={areaList.List}
              onChangeData={onChangeData}
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <ErrorMsg>{errorValidate}</ErrorMsg>
          <SaveArea>
            <PrimaryButton onClick={onSave} text="บันทึก" />
          </SaveArea>
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;
  padding: 24px;

  svg {
    cursor: pointer;
  }
`

const SaveArea = styled.div`
  width: 160px;
  height: 200px;
  margin: 32px 24px 0 0;
`

const ErrorMsg = styled.div`
  margin: 45px 0 0 24px;
  color: red;
`

const mapStateToProps = (state: RootState) => {
  return {
    modelMappingList: selectModelMappingList(state),
    areaList: selectAreaList(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ModelDetail)
