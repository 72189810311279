import { createStandardAction, createAsyncAction } from 'typesafe-actions'

import { GetAreaListApi, GetAreaListReq } from './types'

export const reset = createStandardAction('admin/area/RESET_ACTION')<any>()

export const getArealListReq = createAsyncAction(
  'adminpj/area/GET_AREA_LIST_REQUEST',
  'adminpj/area/GET_AREA_LIST_SUCCESS',
  'adminpj/area/GET_AREA_LIST_FAILURE'
)<GetAreaListReq, GetAreaListApi, any>()
