import { combineReducers } from 'redux'
import search from 'features/search/search.reducer'
import wbs from 'features/wbs/wbs.reducer'
import sensitive from 'features/sensitive/sensitive.reducer'
import activity from 'features/activity/activity.reducer'
import tasklane from 'features/tasklane/tasklane.reducer'
import selectedProject from 'features/selectedProject/selectedProject.reducer'
import project from 'features/project/project.reducer'
import login from 'features/login/login.reducer'
import common from 'features/common/common.reducer'

import mainconActivity from 'features/maincon/activity/reducer'
import mainconDashboard from 'features/maincon/dashboard/reducer'
import mainconSeemore from 'features/maincon/seemore/reducer'
import mainconHistory from 'features/maincon/history/reducer'

import cmpjQcDashboard from 'features/cmpj/qcdashboard/reducer'
import cmpjCmDashboard from 'features/cmpj/cmdashboard/reducer'
import cmpjActivityDetail from 'features/cmpj/activity/reducer'
import cmpjSeemore from 'features/cmpj/seemore/reducer'
import cmpjSeemoreQc from 'features/cmpj/seemoreQc/reducer'
import cmpjChecking from 'features/cmpj/cmchecking/reducer'

import cmpjZoneDashboard from 'features/cmpj/zone/dashboard/reducer'
import cmpjZoneReport from 'features/cmpj/zone/report/reducer'
import cmpjZoneSeemore from 'features/cmpj/zone/seemore/reducer'

import cmpjCommissioningDashboard from 'features/cmpj/commissioning/dashboard/reducer'

import cmpjConstructDashboard from 'features/cmpj/sitevisit/construct/dashboard/reducer'
import cmpjAuditPlanDashboard from 'features/cmpj/sitevisit/auditplan/dashboard/reducer'

import cmpjAuditRoad from 'features/cmpj/road/audit/reducer'
import cmpjConstructRoad from 'features/cmpj/road/construct/dashboard/reducer'
import cmpjConstructRoadChecking from 'features/cmpj/road/construct/checking/reducer'
import adminArea from 'features/admin/area/reducer'
import adminModel from 'features/admin/model/reducer'
import adminFloorPlan from 'features/admin/floorPlan/reducer'
import adminZone from 'features/admin/zone/reducer'
import adminCommission from 'features/admin/commission/reducer'
import adminProject from 'features/admin/project/reducer'
import adminPosition from 'features/admin/position/reducer'
import adminUser from 'features/admin/user/reducer'
import adminQcType from 'features/admin/qcType/reducer'
import adminTeam from 'features/admin/team/reducer'
import adminEmail from 'features/admin/email/reducer'
import adminqcUnit from 'features/adminqc/unit/reducer'

import uploadFile from 'features/uploadfile/reducer'

import masterData from 'features/masterData/reducer'

import qcUnitDashboard from 'features/qc/dashboard/reducer'
import qcDocument from 'features/qc/document/reducer'
import qcCommissioning from 'features/qc/commissioning/reducer'
import qcCommissChecking from 'features/qc/commissioning/checking/reducer'
import qcUnitChecking from 'features/qc/unit/checking/reducer'
import qcProjects from 'features/qc/projects/reducer'
import qcZoneChecking from 'features/qc/zone/checking/reducer'
import qcConstrucplan from 'features/qc/sitevisite/reducer'
import qcRoad from 'features/qc/road/reducer'

import qcRoadConstructChecking from 'features/qc/constructRoad/checking/reducer'

import pmr from 'features/pmr/reducer'

import approvalDashboard from 'features/approval/dashboard/reducer'
import approvalList from 'features/approval/list/reducer'
import approvalActivity from 'features/approval/activity/reducer'

import masterChecklist from 'features/checklist/master/reducer'
import localChecklist from 'features/checklist/local/reducer'
import unitChecklist from 'features/checklist/unit/reducer'
import planChecklist from 'features/checklist/constructPlan/reducer'
import auditPlanChecklist from 'features/checklist/auditPlan/reducer'
import roadConstructChecklist from 'features/checklist/constructRoad/reducer'
import auditRoadChecklist from 'features/checklist/auditRoad/reducer'

import masterDocument from 'features/document/master/reducer'
import localDocument from 'features/document/local/reducer'

import reportUnit from 'features/report/unit/reducer'
import reportZone from 'features/report/zone/reducer'
import reportCommission from 'features/report/commissioning/reducer'
import reportConstructPlan from 'features/report/constructPlan/reducer'
import reportAuditPlan from 'features/report/auditPlan/reducer'
import reportConstructRoad from 'features/report/constructRoad/reducer'
import reportAuditRoad from 'features/report/auditRoad/reducer'

import reportCommon from 'features/report/common/reducer'
import reportFunction from 'features/report/function/reducer'
import reportImage from 'features/report/imagelist/reducer'

import adminReportCommission from 'features/adminReport/commissioning/reducer'
import adminReportCommonArea from 'features/adminReport/commonArea/reducer'
import adminReportUnit from 'features/adminReport/unit/reducer'
import adminReportConstructPlan from 'features/adminReport/constructPlan/reducer'
import adminReportAuditPlan from 'features/adminReport/auditPlan/reducer'
import adminReportConstructRoad from 'features/adminReport/constructRoad/reducer'
import adminReportAuditRoad from 'features/adminReport/auditRoad/reducer'
import adminReportPerformance from 'features/adminReport/performance/reducer'
import adminReportSQDByProject from 'features/adminReport/sqdbyproject/reducer'
import adminReportSQDByMaincon from 'features/adminReport/sqdbymaincon/reducer'
import adminReportCertificate from 'features/adminReport/certificate/reducer'
import adminReportScore from 'features/adminReport/score/reducer'
import adminReportDaily from 'features/adminReport/daily/reducer'
import adminReportMonthly from 'features/adminReport/monthly/reducer'

import adminpjPrimavera from 'features/adminpj/primavera/reducer'
import adminpjModelHouse from 'features/adminpj/modelHouse/reducer'
import adminSyncPrimaveraTask from 'features/admin/sync-primavera-task/reducer'
import filter from 'features/filter/reducer'
import siricon from 'features/siricon/reducer'
import adminUpdateActivityProgress from 'features/admin/update-activity-progress/reducer'
import adminMappingTemplateCode from 'features/admin/mapping-template-code/reducer'
import autoEmail from 'features/admin/auto-email/reducer'
import adminSettingConfig from 'features/admin/setting-config/reducer'
import adminQcBackwardUnit from 'features/adminqc/backward-unit/reducer'

const rootReducer = combineReducers({
  adminQcBackwardUnit,
  adminSettingConfig,
  autoEmail,
  search,
  wbs,
  sensitive,
  activity,
  tasklane,
  selectedProject,
  project,
  login,
  common,

  mainconActivity,
  mainconDashboard,
  mainconSeemore,
  mainconHistory,

  cmpjQcDashboard,
  cmpjCmDashboard,
  cmpjActivityDetail,
  cmpjSeemore,
  cmpjSeemoreQc,
  cmpjChecking,

  cmpjZoneDashboard,
  cmpjZoneReport,
  cmpjZoneSeemore,

  cmpjCommissioningDashboard,

  cmpjConstructDashboard,
  cmpjAuditPlanDashboard,

  cmpjAuditRoad,
  cmpjConstructRoad,
  cmpjConstructRoadChecking,

  // checklist
  masterChecklist,
  localChecklist,
  unitChecklist,
  planChecklist,
  auditPlanChecklist,
  roadConstructChecklist,
  auditRoadChecklist,

  // document
  masterDocument,
  localDocument,
  adminArea,
  adminModel,
  adminFloorPlan,
  adminZone,
  adminCommission,
  adminProject,
  adminPosition,
  adminUser,
  adminQcType,
  adminTeam,
  adminqcUnit,
  adminEmail,

  uploadFile,

  masterData,

  qcUnitDashboard,
  qcDocument,
  qcCommissioning,
  qcUnitChecking,
  qcProjects,
  qcZoneChecking,
  qcCommissChecking,
  qcConstrucplan,
  qcRoad,
  qcRoadConstructChecking,

  pmr,

  approvalDashboard,
  approvalList,
  approvalActivity,

  reportUnit,
  reportZone,
  reportCommon,
  reportFunction,
  reportImage,
  reportCommission,
  reportConstructPlan,
  reportAuditPlan,
  reportConstructRoad,
  reportAuditRoad,

  adminReportCommission,
  adminReportCommonArea,
  adminReportUnit,
  adminReportConstructPlan,
  adminReportAuditPlan,
  adminReportConstructRoad,
  adminReportAuditRoad,
  adminReportPerformance,
  adminReportSQDByProject,
  adminReportSQDByMaincon,
  adminReportCertificate,
  adminReportScore,
  adminReportDaily,
  adminReportMonthly,

  filter,

  adminpjPrimavera,
  adminpjModelHouse,
  adminSyncPrimaveraTask,
  siricon,
  adminUpdateActivityProgress,
  adminMappingTemplateCode,
})

export default rootReducer
