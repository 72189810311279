import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectProjectCode,
  selectEvent as selectProjectEvent,
} from 'features/approval/dashboard/selector'

import {
  selectList,
  selectEvent,
  selectSorting,
} from 'features/approval/list/selector'
import { sortingTask } from 'features/approval/list/actions'

import {
  getMSApproveList,
  saveMSActionActivity,
} from 'features/approval/list/api'
import {
  getWbsListSelector,
  getWbsMapParentsSelector,
  getWbsPathMapSelector,
  getEvent,
} from 'features/wbs/wbs.selector'
import RowItem from './RowItem'
import { RejectModal, ApproveModal } from 'Components/Activity'
import { UpdateActivityReq } from 'features/approval/activity/types'
import { Activity } from 'features/approval/list/types'
import { EventModal } from 'Components/Shared'
import ApprovalTab from 'Components/Shared/Tab/ApprovalTab'
import { Col, Row } from 'antd'
import { fetchWbsListRequest } from 'features/wbs/wbs.api'
import { getWbsName } from 'utils/wbs'
import { getWbsList } from 'features/wbs/wbs.actions'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: 'รายการคำขอ',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ApprovalList: React.ComponentType<Props> = props => {
  const {
    UserEmail,
    Token,
    ProjectCode,
    TaskList,
    Event,
    WBSEvent,
    wbsPathMap,
    Sorting,
    wbsList,
  } = props
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)
  const [currentItem, setCurrentItem] = useState({} as Activity)
  const [enable, setEnable] = useState(false)
  const dispatch = useDispatch()

  const projectCode = ProjectCode + '-MS'

  useEffect(() => {
    if (ProjectCode != '') {
      dispatch(getMSApproveList(Token, { UserEmail, ProjectCode: projectCode }))
      dispatch(getWbsList({ UserEmail, ProjectCode: projectCode, Token }))
      dispatch(sortingTask({ name: 'FinishDate', sort: 'asc' }))
    }
  }, [dispatch, Token, UserEmail, ProjectCode])

  const callSaveActivityForPJ = (
    task: any,
    action: 'APPV' | 'REJT',
    remark?: string
  ) => {
    const data: UpdateActivityReq = {
      ProjectCode: projectCode,
      UserEmail,
      ActivityID: task.ActivityID,
      Action: action,
      Reason: remark || '',
      Progress: task.LastReqProgress,
    }
    setEnable(true)
    dispatch(saveMSActionActivity(data, Token))
  }

  const onSubmitReject = (item: Activity, remark: string) => {
    callSaveActivityForPJ(item, 'REJT', remark)
    setShowRejectModal(false)
  }

  const onSubmitApprove = (item: Activity) => {
    callSaveActivityForPJ(item, 'APPV')
    setShowApproveModal(false)
  }

  const onApprove = (item: Activity) => {
    setCurrentItem(item)
    setShowApproveModal(true)
  }

  const onReject = (item: Activity) => {
    setCurrentItem(item)
    setShowRejectModal(true)
  }

  const rejectModal = (
    <RejectModal
      visible={showRejectModal}
      onClose={() => setShowRejectModal(false)}
      onSubmit={(val: string) => onSubmitReject(currentItem, val)}
      title="ไม่อนุมัติ"
      placeholder="เหตุผลที่ไม่อนุมัติ"
    />
  )

  const approveModal = (
    <ApproveModal
      visible={showApproveModal}
      onClose={() => setShowApproveModal(false)}
      onSubmit={() => onSubmitApprove(currentItem)}
      title="คุณต้องการอนุมัติงานนี้ใช่ไหม"
    />
  )

  const content =
    TaskList.length === 0 ? (
      <Row type="flex" justify="center">
        ไม่มีงานรออนุมัติ
      </Row>
    ) : (
      TaskList.map((item, index) => {
        const wbsName = wbsPathMap[item.WbsID]
        return (
          <RowItem
            key={index}
            item={item}
            wbsName={wbsName}
            onApproveTask={item => onApprove(item)}
            onRejectTask={item => onReject(item)}
          />
        )
      })
    )

  const getIcon = (type: string): string => {
    switch (type) {
      case 'desc':
        return '/img/ic-down.png'
      case 'asc':
        return '/img/ic-arrow-actions.png'
      default:
        return '/img/ic-sort.svg'
    }
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  return (
    <>
      <EventModal
        event={[Event, WBSEvent]}
        enable={enable}
        successCalback={() => {}}
        errorCalback={() => {}}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <ApprovalTab selected="Master Plan" />
      <MainPage>
        <Row type="flex" justify="space-between">
          <Col>
            {' '}
            <Title> รายการคำขอ ({TaskList.length})</Title>{' '}
          </Col>
          <Col>
            <Sort onClick={() => onSort('FinishDate')}>
              <SortTxt>วันสิ้นสุดงาน</SortTxt>
              <img
                src={getIcon(Sorting.type)}
                width={16}
                height={16}
                alt="arrow icon"
              />
            </Sort>
          </Col>
        </Row>
        {content}
      </MainPage>
      {rejectModal}
      {approveModal}
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;

  padding-top: 30px;
  width: 1110px;
  margin: 0 auto;
  overflow-y: scroll;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
  margin-bottom: 16px;
`

const SortTxt = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #8b99a7;
  margin-right: 12px;
`

const Sort = styled.div`
  cursor: pointer;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectCode: selectProjectCode(state),
    EventProject: selectProjectEvent(state),
    TaskList: selectList(state),
    Event: selectEvent(state),
    WBSEvent: getEvent(state),
    wbsPathMap: getWbsPathMapSelector(state),
    Sorting: selectSorting(state),
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalList)
